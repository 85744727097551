import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import {
    BazaNcBankAccountAchDto,
    BazaNcBootstrapDto,
    BazaNcCreditCardDto,
    BazaNcDwollaCustomerDetailDto,
} from '@scaliolabs/baza-nc-shared';
import { BazaWebUtilSharedService } from '@scaliolabs/baza-web-utils';
import { PaymentMethodsConfig, PurchaseState, PurchaseService, UnlinkCreditCardStripe, PaymentMethodType } from '../../../data-access';

@Component({
    selector: 'app-payment-edit-list',
    templateUrl: './list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentEditListComponent implements OnInit {
    limits$ = this.store.select(PurchaseState.limits);

    @Input()
    selectedPaymentMethod: PaymentMethodType;

    @Input()
    creditCardResponse: BazaNcCreditCardDto;

    @Input()
    dwollaCustomerDetailResponse: BazaNcDwollaCustomerDetailDto;

    @Input()
    bankAccountResponse: BazaNcBankAccountAchDto;

    @Input()
    isPurchaseAboveLimit: boolean;

    @Input()
    hasEnoughFunds = true;

    @Input()
    config: PaymentMethodsConfig;

    @Input()
    initData: BazaNcBootstrapDto;

    @Output()
    modalTitleChange: EventEmitter<string> = new EventEmitter();

    @Output()
    paymentMethodChange: EventEmitter<PaymentMethodType> = new EventEmitter();

    @Output()
    addUpdatePaymentMethodClick: EventEmitter<PaymentMethodType> = new EventEmitter();

    deleteCCConfirmVisible = false;
    PAYMENT_METHOD_TYPE = PaymentMethodType;
    i18nBasePath = 'dwpf.pymtEdit.list';

    constructor(private store: Store, public purchaseService: PurchaseService, public readonly wts: BazaWebUtilSharedService) {}

    ngOnInit(): void {
        // set modal title in parent component
        const flags = [
            this.wts.isCardAvailable(this.initData),
            this.wts.isNCBankAccountAvailable(this.initData),
            this.wts.isDwollaAvailable(this.initData),
        ];
        const modalTitle =
            flags.filter(Boolean).length <= 1
                ? this.wts.getI18nLabel(this.i18nBasePath, 'title.singular')
                : this.wts.getI18nLabel(this.i18nBasePath, 'title.plural');
        this.modalTitleChange.emit(modalTitle);
    }

    onAccountBalanceSelect() {
        if (this.showAccBalanceRadioBtn) {
            this.paymentMethodChange.emit(PaymentMethodType.accountBalance);
        }
    }

    onBankAccountSelect() {
        if (!this.wts.isNCBankAccountAvailable(this.initData)) {
            this.onBankAccountUpdate();
        } else if (this.wts.isNCBankAccountAvailable(this.initData) && this.showACHRadioBtn) {
            this.paymentMethodChange.emit(PaymentMethodType.bankAccount);
        }
    }

    onBankAccountUpdate($event: Event = null) {
        if ($event) {
            $event.stopImmediatePropagation();
        }

        this.addUpdatePaymentMethodClick.emit(PaymentMethodType.bankAccount);
    }

    onCreditCardSelect() {
        if (!this.wts.isCardAvailable(this.initData)) {
            this.onCreditCardUpdate();
        } else if (!this.isPurchaseAboveLimit && this.wts.isCardAvailable(this.initData) && this.showCCRadioBtn) {
            this.paymentMethodChange.emit(PaymentMethodType.creditCard);
        }
    }

    onDeleteCreditCard($event: Event) {
        $event.stopImmediatePropagation();
        this.deleteCCConfirmVisible = true;
    }

    onCreditCardUpdate() {
        this.addUpdatePaymentMethodClick.emit(PaymentMethodType.creditCard);
    }

    handleDelete(): void {
        this.store.dispatch(new UnlinkCreditCardStripe()).subscribe(() => {
            this.wts.refreshInitData$.next();
            this.deleteCCConfirmVisible = false;
            this.onCreditCardUpdate();
        });
    }

    public getPurchaseLimitWarningMsg(maxAmount: string) {
        return this.wts.getI18nLabel(this.i18nBasePath, 'card.limitsWarning', { maxAmount });
    }

    public get isAccBalanceDisabled(): boolean {
        return !this.wts.isDwollaAvailable(this.initData) || !this.hasEnoughFunds;
    }

    public get showAccBalanceRadioBtn() {
        return this.wts.isDwollaAvailable(this.initData);
    }

    public get showACHRadioBtn() {
        let showRadioBtn = false;

        if (this.wts.isForeignInvestor(this.initData)) {
            showRadioBtn = this.wts.isNCBankAccountAvailable(this.initData) && this.wts.isCardAvailable(this.initData);
        } else {
            showRadioBtn =
                this.wts.isNCBankAccountAvailable(this.initData) &&
                (this.wts.isCardAvailable(this.initData) || this.wts.isDwollaAvailable(this.initData));
        }

        return showRadioBtn;
    }

    public get showCCRadioBtn() {
        let showRadioBtn = false;

        if (this.wts.isForeignInvestor(this.initData)) {
            showRadioBtn = this.wts.isNCBankAccountAvailable(this.initData) && this.wts.isCardAvailable(this.initData);
        } else {
            showRadioBtn =
                this.wts.isCardAvailable(this.initData) &&
                (this.wts.isNCBankAccountAvailable(this.initData) || this.wts.isDwollaAvailable(this.initData));
        }

        return showRadioBtn;
    }
}

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import {
    BazaNcBankAccountExport,
    BazaNcBankAccountGetDefaultByTypeResponse,
    BazaNcBankAccountLinkOnSuccessRequest,
    BazaNcBankAccountType,
    BazaNcBootstrapDto,
    BazaNcDwollaCustomerDetailDto,
    BazaNcTransferRequest,
} from '@scaliolabs/baza-nc-shared';
import { DwollaAddFundsModalComponent, DwollaAddFundsNotificationComponent, PaymentItemModule } from '@scaliolabs/baza-web-ui-components';
import { BazaLinkUtilSharedService, BazaWebUtilSharedService, Message, UtilModule } from '@scaliolabs/baza-web-utils';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { Observable, catchError, of, tap } from 'rxjs';
import { AccountBalanceExitReason, GetDefaultBankAccount, PurchaseService, TransferFunds } from '../../../../data-access';

@UntilDestroy()
@Component({
    selector: 'app-account-balance',
    templateUrl: './account-balance.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        UtilModule,
        PaymentItemModule,
        DwollaAddFundsModalComponent,
        DwollaAddFundsNotificationComponent,
        NzAlertModule,
        NzButtonModule,
        NzCollapseModule,
        NzModalModule,
    ],
})
export class DwollaAccountBalanceComponent implements OnInit {
    @Input()
    initData: BazaNcBootstrapDto;

    @Input()
    enableTopup = true;

    @Input()
    dwollaCustomerDetail!: BazaNcDwollaCustomerDetailDto;

    @Input()
    dwollaDefaultCashInAccount!: BazaNcBankAccountGetDefaultByTypeResponse;

    @Output()
    refreshAccountBalanceData = new EventEmitter<void>();

    addFundsError$: Observable<Message | undefined>;
    addFundsForm: UntypedFormGroup;
    isAddFundsFormVisible = false;
    openTransferFundsModal = true;
    isAddFundsNotificationVisible = false;
    isPanelOpen = false;
    i18nBasePath = 'dwacc.payment.balance';

    constructor(
        private readonly cdr: ChangeDetectorRef,
        private readonly fb: UntypedFormBuilder,
        private readonly store: Store,
        public readonly purchaseService: PurchaseService,
        public readonly uts: BazaLinkUtilSharedService,
        public readonly wts: BazaWebUtilSharedService,
    ) {}

    public get walletSupportLinkConfig(): string {
        return `${this.i18nBasePath}.alerts.walletSupport.linkConfig`;
    }

    ngOnInit(): void {
        this.resetAddFundsForm();
    }

    onAccountBalanceClicked() {
        this.purchaseService.processAccountBalanceCreation({
            isDwollaAvailable: this.wts.isDwollaAvailable(this.initData),
            onExit: (reason) => {
                switch (reason) {
                    case AccountBalanceExitReason.DwollaBalanceAlreadyAvailable:
                        this.triggerDwollaPlaidFlow();
                        break;
                }
            },
            onInvestorTouched: (response) => {
                const isDwollaAvailable = response?.isDwollaAvailable ?? false;

                if (this.dwollaCustomerDetail) {
                    this.dwollaCustomerDetail.isDwollaAvailable = isDwollaAvailable;
                }

                this.purchaseService.showDwollaAccountCreationError$.next(!isDwollaAvailable);
                this.cdr.detectChanges();

                if (isDwollaAvailable) {
                    this.triggerDwollaPlaidFlow();
                }
            },
        });
    }

    onAddFundsClicked() {
        if (this.wts.isDwollaCashInAccountLinked(this.initData)) {
            this.openTransferFundsPopup();
        } else {
            this.triggerDwollaPlaidFlow();
        }
    }

    handleAddFundsCancel() {
        this.isAddFundsFormVisible = false;
        this.resetAddFundsForm();
    }

    displayAddFundsNotification() {
        this.isAddFundsNotificationVisible = true;
    }

    handleEditCashInAccount() {
        this.isAddFundsFormVisible = false;
        this.triggerDwollaPlaidFlow(true);
    }

    submitAddFundsForm() {
        const transferRequest: BazaNcTransferRequest = {
            amount: this.addFundsForm.get('transferAmount')?.value?.toString(),
        };

        this.addFundsError$ = this.store.dispatch(new TransferFunds(transferRequest)).pipe(
            tap((result) => {
                if (result?.type === 'error') return;

                this.wts.refreshInitData$.next();
                this.handleAddFundsCancel();
                this.displayAddFundsNotification();
            }),
            catchError(() =>
                of({
                    type: 'error',
                    text: this.wts.getI18nLabel('uic.addFunds', 'form.alerts.transferError.label'),
                } as Message),
            ),
        );
    }

    triggerDwollaPlaidFlow(reopenAddFundsForm = false) {
        if (!this.enableTopup) {
            this.refreshAccountBalanceData.emit();
            return;
        }

        this.purchaseService.processPlaidPluginFlow({
            onSuccess: (publicToken, identity) => {
                // link cash-in account
                this.linkBankAccount(
                    {
                        publicToken,
                        type: BazaNcBankAccountType.CashIn,
                        export: [BazaNcBankAccountExport.Dwolla],
                        setAsDefault: true,
                        identity,
                    },
                    () => {
                        if (this.openTransferFundsModal) {
                            reopenAddFundsForm = true;
                        }

                        // cash-out not linked already, attach cash-in as default cash-out
                        if (!this.wts.isDwollaCashOutAccountLinked(this.initData)) {
                            this.linkBankAccount(
                                {
                                    publicToken,
                                    type: BazaNcBankAccountType.CashOut,
                                    export: [BazaNcBankAccountExport.Dwolla],
                                    setAsDefault: true,
                                    identity,
                                },
                                () => {
                                    this.store.dispatch(new GetDefaultBankAccount(BazaNcBankAccountType.CashOut, { throwError: false }));
                                    this.handleOnPlaidCompletionFlow(reopenAddFundsForm);
                                },
                            );
                        } else {
                            this.handleOnPlaidCompletionFlow(reopenAddFundsForm);
                        }
                    },
                );
            },
            onExit: () => {
                this.handleOnPlaidCompletionFlow(reopenAddFundsForm);
            },
        });
    }

    private handleOnPlaidCompletionFlow(reopenAddFundsForm = false) {
        // trigger add funds flow if/when required
        if (reopenAddFundsForm) {
            this.openTransferFundsPopup();
        }

        this.openTransferFundsModal = true;
        this.refreshAccountBalanceData.emit();
    }

    private linkBankAccount(request: BazaNcBankAccountLinkOnSuccessRequest, onSuccess: () => void = null) {
        this.purchaseService.processDwollaLinkBankAccount(request, onSuccess);
    }

    private openTransferFundsPopup() {
        this.resetAddFundsForm();
        this.isAddFundsFormVisible = true;
    }

    private resetAddFundsForm(): void {
        this.addFundsForm = this.fb.group({
            transferAmount: [null, [Validators.required]],
        });
        this.addFundsError$ = null;
    }

    public updateCashInAccount() {
        this.openTransferFundsModal = false;
        this.triggerDwollaPlaidFlow();
    }
}

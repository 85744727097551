import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { Observable, combineLatest, filter, finalize, map, startWith, switchMap, tap } from 'rxjs';
import { BazaFormValidatorService } from '@scaliolabs/baza-core-ng';
import { BazaNcIntegrationListingsDto } from '@scaliolabs/baza-nc-integration-shared';
import {
    BazaNcBankAccountAchDto,
    BazaNcBootstrapDto,
    BazaNcCreditCardDto,
    BazaNcDwollaCustomerDetailDto,
    BazaNcLimitsDto,
    BazaNcPurchaseFlowTransactionType,
    PurchaseFlowDto,
    PurchaseFlowSessionDto,
} from '@scaliolabs/baza-nc-shared';
import {
    BazaWebUtilSharedService,
    CARD_BRAND_ICONS,
    LoaderService,
    PaymentBankAccountConfig,
    PaymentButtonStyleEnum,
    PaymentCardConfig,
    PriceCentsPipe,
} from '@scaliolabs/baza-web-utils';
import { PaymentAccountBalanceConfig } from '@scaliolabs/baza-web-ui-components';

import {
    GetLimits,
    LoadAccountBalance,
    LoadBankAccount,
    LoadCreditCardStripe,
    PaymentMethodsConfig,
    PurchaseState,
    PurchaseService,
    StartPurchase,
    PaymentMethodType,
} from '../data-access';

@UntilDestroy()
@Component({
    selector: 'app-purchase-methods',
    templateUrl: './methods.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchaseMethodsComponent implements OnInit, OnChanges {
    @Input()
    initData: BazaNcBootstrapDto;

    @Input()
    isPurchaseAboveLimit: boolean;

    @Input()
    config: PaymentMethodsConfig;

    @Output()
    updateSubmitBtnDisableStatus = new EventEmitter<boolean>();

    @Output()
    public readonly paymentMethodAdded = new EventEmitter<boolean>();

    limits$ = this.store.select(PurchaseState.limits);
    bankAccount$ = this.store.select(PurchaseState.bankAccount).pipe(startWith(null));
    creditCard$ = this.store.select(PurchaseState.creditCardStripe).pipe(startWith(null));
    dwollaCustomerDetail$ = this.store.select(PurchaseState.dwollaCustomerDetail).pipe(startWith(null));

    dwollaCustomerDetail: BazaNcDwollaCustomerDetailDto;
    purchaseStart: PurchaseFlowDto;
    selectedPaymentMethod: PaymentMethodType = null;
    entity: BazaNcIntegrationListingsDto;
    numberOfShares: number;
    limits: BazaNcLimitsDto;

    isForeignInvestor = false;
    isDwollaAvailable = false;
    isCardAvailable = false;
    isNCBankAccountAvailable = false;
    dwollaBalanceAmount = 0;
    isPaymentEditModalVisible = false;
    cardBrandIcons = CARD_BRAND_ICONS;

    switchToCardAndCreateSession = false;
    switchToAchAndCreateSession = false;
    switchForCardDeletionCase = false;

    @ViewChild('tplStripeModalTitle') tplStripeModalTitle: TemplateRef<Component>;
    public i18nBasePath = 'dwpf.methods';
    PAYMENT_BUTTON_STYLE = PaymentButtonStyleEnum;

    dataStream$: Observable<{
        bank?: BazaNcBankAccountAchDto;
        card?: BazaNcCreditCardDto;
        dwollaCustomerDetail?: BazaNcDwollaCustomerDetailDto;
    }>;

    constructor(
        private readonly loader: LoaderService,
        private readonly priceCents: PriceCentsPipe,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly sanitizer: DomSanitizer,
        private readonly store: Store,
        public readonly bazaFormValidatorService: BazaFormValidatorService,
        public readonly purchaseService: PurchaseService,
        public readonly wts: BazaWebUtilSharedService,
    ) {
        this.purchaseStart = this.store.selectSnapshot(PurchaseState.purchaseStart);
        this.entity = this.store.selectSnapshot(PurchaseState.cart);
        this.numberOfShares = this.store.selectSnapshot(PurchaseState.numberOfShares);
    }

    ngOnInit(): void {
        this.dataStream$ = this.loadData().pipe(
            switchMap(() => {
                this.dwollaCustomerDetail = this.store.selectSnapshot(PurchaseState.dwollaCustomerDetail);
                this.resetVariables();
                this.setDefaultPaymentMethod();
                return this.updatePurchaseSession();
            }),
            switchMap(() => {
                return combineLatest([this.bankAccount$, this.creditCard$, this.dwollaCustomerDetail$]).pipe(
                    map(([bank, card, dwollaCustomerDetail]) => ({
                        bank,
                        card,
                        dwollaCustomerDetail,
                    })),
                    filter((pair) => !!this.filterData(pair)),
                    tap(() => {
                        this.loader.hide();
                    }),
                );
            }),
            finalize(() => {
                this.loader.hide();
            }),
        );
    }

    loadData() {
        const requests = [new GetLimits()];

        if (this.config?.showAccountBalance && !this.wts.isForeignInvestor(this.initData)) {
            requests.push(new LoadAccountBalance());
        }

        if (this.config?.showBankAccount) {
            requests.push(new LoadBankAccount());
        }

        if (this.config?.showCreditCard) {
            requests.push(new LoadCreditCardStripe());
        }

        return this.store.dispatch(requests);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.initData?.firstChange) {
            this.resetVariables();

            this.switchForCardDeletionCase =
                this.wts.isCardAvailable(changes.initData?.previousValue as BazaNcBootstrapDto) &&
                !this.wts.isCardAvailable(changes.initData?.currentValue as BazaNcBootstrapDto);

            // when user confirmed CC deletion (with background iFrame loading)
            if (this.switchForCardDeletionCase) {
                this.switchForCardDeletionCase = false;
                this.setDefaultPaymentMethod();
                this.updatePurchaseSession();
            }

            // when user adds ACH Bank through "Edit Popup"
            else if (this.switchToAchAndCreateSession) {
                this.switchToAchAndCreateSession = false;
                this.onSelectPaymentMethod(PaymentMethodType.bankAccount);
            }

            // when user adds CC through "Edit Popup"
            else if (this.switchToCardAndCreateSession) {
                this.switchToCardAndCreateSession = false;
                this.onSelectPaymentMethod(PaymentMethodType.creditCard);
            }
        }
    }

    filterData(pair: Record<string, unknown>) {
        return (
            (this.config?.showBankAccount ? !!pair.bank : true) &&
            (this.config?.showCreditCard ? !!pair.card : true) &&
            (this.config?.showAccountBalance && !this.wts.isForeignInvestor(this.initData) ? !!pair.dwollaCustomerDetail : true)
        );
    }

    private resetVariables() {
        this.isForeignInvestor = this.config?.showAccountBalance ? this.wts.isForeignInvestor(this.initData) : true;
        this.isDwollaAvailable = this.config?.showAccountBalance ? this.wts.isDwollaAvailable(this.initData) : false;
        this.isCardAvailable = this.config?.showCreditCard ? this.wts.isCardAvailable(this.initData) : false;
        this.isNCBankAccountAvailable = this.config?.showBankAccount ? this.wts.isNCBankAccountAvailable(this.initData) : false;
        this.dwollaBalanceAmount = this.config?.showAccountBalance ? this.getDwollaBalanceAmount(this.dwollaCustomerDetail) : 0;
        this.paymentMethodAdded.emit(this.isPaymentMethodAdded);
        this.updateSubmitBtnDisableStatus.emit(this.isSubmitBtnDisabled);
    }

    // on open payment edit modals
    public openPaymentEditModal() {
        this.isPaymentEditModalVisible = true;
    }

    // on close payment edit modal
    private closePaymentEditModal() {
        this.isPaymentEditModalVisible = false;
    }

    public onSelectPaymentMethod(paymentMethod: PaymentMethodType) {
        this.selectedPaymentMethod = paymentMethod;
        this.router
            .navigate(['.'], {
                relativeTo: this.route,
                queryParams: {
                    methods: true,
                },
                queryParamsHandling: 'merge',
            })
            .then(() => {
                this.updatePurchaseSession();
            });
    }

    // when user confirmed bank / cc add form / iframe
    public onSubmitAddFormFromModal(paymentMethod: PaymentMethodType) {
        this.closePaymentEditModal();

        if (paymentMethod === PaymentMethodType.bankAccount) {
            this.onBankLink();
        } else if (paymentMethod === PaymentMethodType.creditCard) {
            this.switchToCardAndCreateSession = true;
            this.wts.refreshInitData$.next();
        }
    }

    public onSelectPaymentMethodAndClose(paymentMethod: PaymentMethodType) {
        this.onSelectPaymentMethod(paymentMethod);
        this.closePaymentEditModal();
    }

    public onBankLink() {
        this.purchaseService
            .processPlaidLegacyFlow()
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.switchToAchAndCreateSession = true;
                this.wts.refreshInitData$.next();
            });
    }

    // when user directly adds CC in empty payment case
    onUpsertCard() {
        this.purchaseService
            .processStripeCCFlow(this.tplStripeModalTitle)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.closePaymentEditModal();
                this.switchToCardAndCreateSession = true;
                this.wts.refreshInitData$.next();
            });
    }

    updatePurchaseSession() {
        const purchase: PurchaseFlowSessionDto = {
            amount: this.entity?.pricePerShareCents * this.numberOfShares,
            numberOfShares: this.numberOfShares,
            offeringId: this.entity?.offeringId,
            transactionType: this.mapPaymentMethodToTransactionType(this.selectedPaymentMethod),
        };

        this.checkSessionDefaultTransactionType(purchase);
        this.updateSubmitBtnDisableStatus.emit(this.isSubmitBtnDisabled);

        return this.store.dispatch(new StartPurchase(purchase));
    }

    private setDefaultPaymentMethod() {
        if (this.isForeignInvestor) {
            this.selectedPaymentMethod = this.isNCBankAccountAvailable
                ? PaymentMethodType.bankAccount
                : this.isCardAvailable
                ? PaymentMethodType.creditCard
                : null;
        } else {
            this.selectedPaymentMethod =
                this.isDwollaAvailable && this.hasEnoughFunds
                    ? PaymentMethodType.accountBalance
                    : this.isNCBankAccountAvailable
                    ? PaymentMethodType.bankAccount
                    : this.isCardAvailable
                    ? PaymentMethodType.creditCard
                    : null;
        }

        if (this.selectedPaymentMethod) {
            this.router.navigate(['.'], {
                relativeTo: this.route,
                queryParams: {
                    methods: true,
                },
                queryParamsHandling: 'merge',
            });
        }
    }

    private checkSessionDefaultTransactionType(purchase: PurchaseFlowSessionDto) {
        // if CC is selected & purchase is above limit, select ACH/TBD as default transaction type for 1st time session start on payment page (BAZA-1686)
        if (this.selectedPaymentMethod === PaymentMethodType.creditCard && this.isPurchaseAboveLimit) {
            const paymentMethod = this.isForeignInvestor ? PaymentMethodType.bankAccount : PaymentMethodType.accountBalance;
            purchase.transactionType = this.mapPaymentMethodToTransactionType(paymentMethod);
        }
    }

    private mapPaymentMethodToTransactionType(paymentMethod: PaymentMethodType) {
        const mappedType =
            paymentMethod === PaymentMethodType.creditCard
                ? BazaNcPurchaseFlowTransactionType.CreditCard
                : paymentMethod === PaymentMethodType.accountBalance
                ? BazaNcPurchaseFlowTransactionType.AccountBalance
                : BazaNcPurchaseFlowTransactionType.ACH;

        return mappedType;
    }

    getDwollaBalanceAmount(dwollaCustomerDetail: BazaNcDwollaCustomerDetailDto): number {
        return parseFloat(dwollaCustomerDetail?.balance?.value ?? '0');
    }

    public get isPaymentMethodEditVisible() {
        let isVisible = true;

        if (this.isForeignInvestor) {
            isVisible = this.isNCBankAccountAvailable || (this.isCardAvailable && !this.isPurchaseAboveLimit);
        } else {
            isVisible =
                this.isNCBankAccountAvailable ||
                (this.isDwollaAvailable && this.hasEnoughFunds) ||
                (this.isCardAvailable && !this.isPurchaseAboveLimit);
        }

        return isVisible;
    }

    public get accountBalanceAddMode() {
        return !this.isDwollaAvailable && !this.isNCBankAccountAvailable && (!this.isCardAvailable || this.isPurchaseAboveLimit);
    }

    public get accountBalanceEditMode() {
        return (
            (this.isDwollaAvailable && this.isAccBalancePaymentMethod) ||
            (this.isDwollaAvailable &&
                !this.hasEnoughFunds &&
                ((!this.isNCBankAccountAvailable && !this.isCardAvailable) ||
                    (!this.isNCBankAccountAvailable && this.isCardAvailable && this.isPurchaseAboveLimit)))
        );
    }

    public get bankAccountAddMode() {
        return (
            !this.isNCBankAccountAvailable &&
            (((!this.isCardAvailable || this.isPurchaseAboveLimit) && this.isDwollaAvailable && !this.hasEnoughFunds) ||
                ((!this.isCardAvailable || this.isPurchaseAboveLimit) && !this.isDwollaAvailable))
        );
    }

    public get bankAccountEditMode() {
        return this.isNCBankAccountAvailable && this.isNCBankAccPaymentMethod;
    }

    public get cardAddMode() {
        if (this.isForeignInvestor) {
            return !this.isNCBankAccountAvailable && !this.isCardAvailable;
        } else {
            return !this.isNCBankAccountAvailable && (!this.isDwollaAvailable || !this.hasEnoughFunds) && !this.isCardAvailable;
        }
    }

    public get cardEditMode() {
        return this.isCardAvailable && this.isCCPaymentMethod;
    }

    public get isCCPaymentMethod(): boolean {
        return this.selectedPaymentMethod === PaymentMethodType.creditCard;
    }

    public get isAccBalancePaymentMethod(): boolean {
        return this.selectedPaymentMethod === PaymentMethodType.accountBalance;
    }

    public get isNCBankAccPaymentMethod(): boolean {
        return this.selectedPaymentMethod === PaymentMethodType.bankAccount;
    }

    public get isSubmitBtnDisabled() {
        let isDisabled = true;

        if (this.isForeignInvestor) {
            isDisabled =
                (!this.isNCBankAccountAvailable && !this.isCardAvailable) ||
                (!this.isNCBankAccountAvailable && this.isCardAvailable && this.isPurchaseAboveLimit);
        } else {
            isDisabled =
                (!this.isNCBankAccountAvailable &&
                    !this.isCardAvailable &&
                    (!this.isDwollaAvailable || (this.isDwollaAvailable && !this.hasEnoughFunds))) ||
                (!this.isNCBankAccountAvailable &&
                    this.isCardAvailable &&
                    this.isPurchaseAboveLimit &&
                    (!this.isDwollaAvailable || (this.isDwollaAvailable && !this.hasEnoughFunds)));
        }

        return isDisabled;
    }

    public get hasEnoughFunds() {
        return this.getDwollaBalanceAmount(this.dwollaCustomerDetail) >= this.getTotalPurchaseAmount;
    }

    public get getTotalPurchaseAmount() {
        return (this.purchaseStart?.total ?? 0) / 100;
    }

    public get showDwollaLowFundsError() {
        return this.isDwollaAvailable && !this.hasEnoughFunds;
    }

    public get lowFundsDifferenceAmount() {
        return this.getTotalPurchaseAmount - this.dwollaBalanceAmount;
    }

    public get isAccountBalanceDisabled() {
        return !this.hasEnoughFunds || !this.isDwollaAvailable;
    }

    get dwollaLowFundsError(): SafeHtml {
        const output = this.wts.getI18nLabel(this.i18nBasePath, 'errors.lowFunds', {
            amount: this.priceCents.transform(this.lowFundsDifferenceAmount),
        });

        return this.sanitizer.bypassSecurityTrustHtml(output);
    }

    get isPaymentMethodAdded() {
        if (this.isForeignInvestor) {
            return this.isNCBankAccountAvailable || this.isCardAvailable;
        } else {
            return this.isNCBankAccountAvailable || this.isCardAvailable || this.isDwollaAvailable;
        }
    }

    get balanceConfig() {
        return {
            style: PaymentButtonStyleEnum.Bordered,
            isAddModeOn: this.accountBalanceAddMode,
            isEditModeOn: this.accountBalanceEditMode,
            hasEnoughFunds: this.hasEnoughFunds,
        } as PaymentAccountBalanceConfig;
    }

    get bankConfig() {
        return {
            style: PaymentButtonStyleEnum.Bordered,
            isAddModeOn: this.bankAccountAddMode,
            isEditModeOn: this.bankAccountEditMode,
        } as PaymentBankAccountConfig;
    }

    get cardConfig() {
        return {
            style: PaymentButtonStyleEnum.Bordered,
            isAddModeOn: this.cardAddMode,
            isEditModeOn: this.cardEditMode,
            isPurchaseAboveLimit: this.isPurchaseAboveLimit,
        } as PaymentCardConfig;
    }
}

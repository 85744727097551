import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { BazaNcBankAccountType, BazaNcBootstrapDto } from '@scaliolabs/baza-nc-shared';
import { PaymentItemBankComponent, PaymentItemCardComponent } from '@scaliolabs/baza-web-ui-components';
import { BazaWebUtilSharedService, CARD_BRAND_ICONS } from '@scaliolabs/baza-web-utils';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { tap } from 'rxjs';
import {
    AccountPaymentMethodsConfig,
    GetDefaultBankAccount,
    GetLimits,
    LoadAccountBalance,
    LoadBankAccount,
    LoadCreditCardStripe,
    PurchaseState,
    PurchaseService,
} from '../../../data-access';
import { DwollaAccountBalanceComponent } from './account-balance/account-balance.component';
import { merge } from 'lodash';

@UntilDestroy()
@Component({
    selector: 'app-payment-methods',
    templateUrl: './dwolla-payment-methods.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        DwollaAccountBalanceComponent,
        PaymentItemBankComponent,
        PaymentItemCardComponent,
        NzGridModule,
        NzSkeletonModule,
    ],
})
export class DwollaPaymentMethodsComponent implements OnInit {
    @Input()
    initData: BazaNcBootstrapDto;

    @Input()
    config?: AccountPaymentMethodsConfig;

    @Input()
    header?: TemplateRef<any>;

    dwollaCustomerDetail$ = this.store.select(PurchaseState.dwollaCustomerDetail);
    dwollaDefaultCashInAccount$ = this.store.select(PurchaseState.dwollaDefaultCashInAccount);
    bankAccount$ = this.store.select(PurchaseState.bankAccount);
    creditCard$ = this.store.select(PurchaseState.creditCardStripe);
    limits$ = this.store.select(PurchaseState.limits);

    cardBrandIcons = CARD_BRAND_ICONS;
    i18nBasePath = 'dwacc.payment';

    constructor(
        private readonly store: Store,
        public readonly purchaseService: PurchaseService,
        public readonly wts: BazaWebUtilSharedService,
    ) {}

    ngOnInit(): void {
        this.checkConfig();
        this.loadData();
    }

    private checkConfig() {
        const defaultConfig: AccountPaymentMethodsConfig = {
            accountBalanceConfig: {
                showAccountBalance: true,
                enableTopup: true,
            },
            bankConfig: {
                showBankAccount: true,
                sectionHeader: null,
            },
            cardConfig: {
                showCreditCard: true,
                sectionHeader: null,
            },
        };

        this.config = merge(defaultConfig, this.config);
    }

    private loadData() {
        const requests = [];

        if (this.config?.accountBalanceConfig?.showAccountBalance && !this.wts.isForeignInvestor(this.initData)) {
            requests.push(new LoadAccountBalance());

            if (this.config?.accountBalanceConfig?.enableTopup) {
                requests.push(new GetDefaultBankAccount(BazaNcBankAccountType.CashIn, { throwError: false }));
            }
        }

        if (this.config?.bankConfig?.showBankAccount) {
            requests.push(new LoadBankAccount());
        }

        if (this.config?.cardConfig?.showCreditCard) {
            requests.push(new GetLimits(), new LoadCreditCardStripe());
        }

        this.store.dispatch(requests);
    }

    // #region Account Balance
    onRefreshAccountBalanceData() {
        const requests = [new LoadAccountBalance()];
        if (this.config?.accountBalanceConfig?.enableTopup) {
            requests.push(new GetDefaultBankAccount(BazaNcBankAccountType.CashIn, { throwError: false }));
        }

        this.store
            .dispatch(requests)
            .pipe(
                untilDestroyed(this),
                tap(() => {
                    this.wts.refreshInitData$.next();
                }),
            )
            .subscribe();
    }
    // #endregion

    // #region Bank Account
    onUpsertBankAccount() {
        this.purchaseService
            .processPlaidLegacyFlow()
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.wts.refreshInitData$.next();
            });
    }
    // #endregion

    // #region Credit Card
    onUnlinkCreditCard(tplStripeModalTitle: TemplateRef<Component>) {
        this.purchaseService
            .unlinkAndProcessStripeCCFlow(tplStripeModalTitle)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.wts.refreshInitData$.next();
            });
    }

    onAddCreditCard(tplStripeModalTitle: TemplateRef<Component>) {
        this.purchaseService
            .processStripeCCFlow(tplStripeModalTitle)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.wts.refreshInitData$.next();
            });
    }
    // #endregion
}

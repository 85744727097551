<div
    appLinkify
    [linkConfigPath]="i18nBasePath + '.linkConfigs'">
    <ng-container *ngIf="config?.isAddModeOn">
        <button
            class="payment-btn payment-btn-inverse payment-btn-buy"
            [class.payment-btn-bordered]="config?.style === PAYMENT_BUTTON_STYLE.Bordered"
            nz-button
            nzBlock
            [disabled]="config?.isPurchaseAboveLimit"
            (click)="upsertCard.emit()">
            <div class="payment-btn__title">
                {{ wts.getI18nLabel(i18nBasePath, 'addMode.title') }}

                <div class="payment-subtitle payment-subtitle-faded">
                    <ng-container *ngIf="wts.getI18nLabel(i18nBasePath, 'addMode.feePrefix')">
                        {{ wts.getI18nLabel(i18nBasePath, 'addMode.feePrefix') }}
                    </ng-container>

                    <span *ngIf="limits?.creditCardPaymentsFixedFee">${{ limits?.creditCardPaymentsFixedFee | numberTwoDigits }} +</span>

                    {{ limits?.creditCardPaymentsFee | numberTwoDigits }}%
                    {{ wts.getI18nLabel(i18nBasePath, 'addMode.feeSuffix') }}
                </div>
            </div>

            <div class="payment-arrow">
                <i class="icon icon-angle-right icon-wt"></i>
            </div>
        </button>

        <div class="payment-subtitle payment-subtitle-faded">
            {{ getPurchaseLimitWarningMsg(limits?.maxCreditCardTransferAmountCents / 100 | priceCents | slice : 0 : -3) }}
        </div>
    </ng-container>

    <ng-container *ngIf="config?.isEditModeOn">
        <ng-container *ngIf="config?.isPurchaseAboveLimit">
            <div
                class="payment-section payment-section-inverse payment-section-buy payment-section-disabled"
                [class.payment-section-bordered]="config?.style === PAYMENT_BUTTON_STYLE.Bordered">
                <div class="payment-section__title">
                    <div
                        class="card-icon"
                        *ngIf="wts.getCardIcon(srcData?.card?.cardType) as cardIcon">
                        <div class="card-icon__item">
                            <svg-icon-sprite [src]="cardIcon"></svg-icon-sprite>
                        </div>
                    </div>

                    {{ wts.getI18nLabel(i18nBasePath, 'editMode.title') }}
                    {{ srcData?.card?.creditCardNumber | lastDigits : 16 }}
                </div>

                <div class="payment-arrow">
                    <i class="icon icon-angle-right icon-wt"></i>
                </div>
            </div>

            <div
                class="payment-subtitle"
                [ngClass]="{ 'payment-subtitle-error': config?.isPurchaseAboveLimit }">
                {{ getPurchaseLimitWarningMsg(limits?.maxCreditCardTransferAmountCents / 100 | priceCents | slice : 0 : -3) }}
            </div>
        </ng-container>

        <ng-container *ngIf="!config?.isPurchaseAboveLimit">
            <ul class="info-list">
                <li class="info-list__item">
                    <div
                        class="card-icon"
                        *ngIf="getCardIcon(srcData?.card?.cardType) as cardIcon">
                        <div class="card-icon__item">
                            <svg-icon-sprite [src]="cardIcon"></svg-icon-sprite>
                        </div>
                    </div>
                    <div
                        class="info-list__label"
                        [innerHTML]="wts.getI18nLabel(this.i18nBasePath, 'editMode.cardDetails.number') | sanitizeHtml"></div>
                    <div
                        class="info-list__text"
                        [innerHTML]="srcData?.card?.creditCardNumber ?? '' | lastDigits : 16"></div>
                </li>
            </ul>

            <div class="payment-subtitle payment-subtitle-faded">
                <span *ngIf="limits?.creditCardPaymentsFixedFee">${{ limits?.creditCardPaymentsFixedFee | numberTwoDigits }} +</span>

                {{ limits?.creditCardPaymentsFee | numberTwoDigits }}%
                {{ wts.getI18nLabel(i18nBasePath, 'editMode.cardDetails.feeSuffix') }}
            </div>
        </ng-container>
    </ng-container>
</div>

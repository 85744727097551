<div
    id="payout-method"
    appLinkify
    [linkConfigPath]="i18nBasePath + '.linkConfigs'">
    <nz-alert
        class="payment-block__alert"
        data-cy="dwolla-available-warning"
        nzType="warning"
        [nzMessage]="wts.getI18nLabel(i18nBasePath, 'alerts.addPayoutMethod')"
        *ngIf="wts.isDwollaAvailable(initData) && !wts.isDwollaCashOutAccountLinked(initData) && isAnyAssetPurchased"></nz-alert>

    <app-payment-item
        [isDisabled]="purchaseService.showDwollaAccountCreationError$ | async"
        [isPaymentLinked]="wts.isDwollaCashOutAccountLinked(initData)"
        (clickContainer)="onPayoutContainerClicked()"
        *ngIf="!wts.isForeignInvestor(initData)">
        <app-payment-item-header>
            {{ wts.getI18nLabel(i18nBasePath, 'title') }}

            <span
                class="payment-subtitle payment-subtitle-faded payment-subtitle-hint"
                *ngIf="wts.isDwollaCashOutAccountLinked(initData)">
                {{ wts.getI18nLabel(i18nBasePath, 'hint') }}
            </span>
        </app-payment-item-header>

        <ng-container *ngIf="wts.isDwollaCashOutAccountLinked(initData)">
            <app-payment-item-detail>{{ dwollaDefaultCashOutAccount?.result?.accountName }}</app-payment-item-detail>

            <app-payment-item-detail>{{ dwollaDefaultCashOutAccount?.result?.accountType }}</app-payment-item-detail>
        </ng-container>

        <app-payment-item-actions>
            <button
                nz-button
                nzSize="small"
                type="button"
                (click)="triggerDwollaPlaidFlow()">
                {{ wts.getI18nLabel(i18nBasePath, 'actions.update') }}
            </button>
        </app-payment-item-actions>
    </app-payment-item>

    <div
        *ngIf="wts.isForeignInvestor(initData)"
        class="payment-subtitle payment-subtitle-faded"
        [innerHTML]="wts.getI18nLabel(i18nBasePath, 'alerts.withdrawalSupport.label') | sanitizeHtml"></div>

    <div
        *ngIf="!wts.isDwollaCashOutAccountLinked(initData) && !wts.isForeignInvestor(initData)"
        class="payment-subtitle payment-subtitle-faded">
        {{ wts.getI18nLabel(i18nBasePath, 'alerts.linkBank') }}
    </div>
</div>
